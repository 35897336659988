import React from 'react';
import './Footer.css';
import { FaInstagram, FaLinkedin, FaPhone, FaEnvelope, FaYoutube, FaTiktok } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import acunmedyaLogo from '../images/acunmedya-logo.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top-line"></div>

            <div className="footer-content">
                <div className="footer-left">
                    <img src={acunmedyaLogo} alt="Acunmedya Akademi Logo" className="footer-logo" />
                    <div className="footer-divider"></div>
                    <div className="footer-contact">
                        <p className="address">
                            İstanbul Nişantaşı Üniversitesi<br />
                            NeoTech Campus Maslak<br />
                            34398 - Sarıyer - İstanbul
                        </p>
                        <p><FaPhone /> (0212) 210 10 10</p>
                        <p><FaEnvelope /> info@acunmedyaakademi.com</p>
                    </div>
                </div>
                <div className="footer-right">
                    <div className="footer-links">
                        <div className="footer-column">
                            <h3>EĞİTİM</h3>
                            <div className="footer-divider-horizontal"></div>
                            <a href="#">Dijital İçerik Ve Yayıncılık</a>
                            <a href="#">Gastronomi</a>
                            <a href="#">Televizyon</a>
                        </div>
                        <div className="footer-column">
                            <h3>ERİŞİLEBİLİRLİK</h3>
                            <div className="footer-divider-horizontal"></div>
                            <a href="#">LMS/Öğrenci Girişi</a>
                            <a href="#">Şifremi Unuttum</a>
                            <a href="#">Duyurular & Haberler</a>
                        </div>
                        <div className="footer-column">
                            <h3>GİZLİLİK</h3>
                            <div className="footer-divider-horizontal"></div>
                            <a href="#">Gizlilik Politikası</a>
                            <a href="#">KVKK Aydınlatma Metni</a>
                            <a href="#">Sıkça Sorulan Sorular</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-social">
                <a href="#" aria-label="Instagram"><FaInstagram /></a>
                <a href="#" aria-label="LinkedIn"><FaLinkedin /></a>
            </div>
            <div className="footer-bottom">
                <div className="footer-legal">
                    <a href="#">Gizlilik Politikası</a>
                    <a href="#">KVKK Aydınlatma Metni</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;