import React, { useState, useRef } from 'react';
import { Tv, Utensils, Monitor, PenTool, ChevronLeft, ChevronRight } from 'lucide-react';
import './Categories.css';

const fonts = [
  'Bebas Neue', 'Bangers', 'Alfa Slab One', 'Bungee', 'Fredoka One',
  'Righteous', 'Permanent Marker', 'Abril Fatface', 'Russo One', 'Lobster'
];

const MenuItem = ({ icon: Icon, text, isActive, onClick }) => (
  <button
    className={`category-item ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    <Icon size={20} color={isActive ? '#FFC107' : '#9CA3AF'} />
    <span>{text}</span>
  </button>
);

const CourseCard = ({ title, date, imageUrl }) => {
  const randomFont = fonts[Math.floor(Math.random() * fonts.length)];
  
  return (
    <div className="course-card" style={{ backgroundImage: `url(${imageUrl})` }}>
      <div className="course-card-content">
        <h3 style={{ fontFamily: randomFont }}>{title.toUpperCase()}</h3>
        <div className="course-card-divider"></div>
        <p>{date}</p>
      </div>
    </div>
  );
};
const Categories = () => {
  const [activeCategory, setActiveCategory] = useState('Televizyon');
  const [fadeIn, setFadeIn] = useState(true);
  const coursesRef = useRef(null);

  const handleCategoryChange = (category) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveCategory(category);
      setFadeIn(true);
      if (coursesRef.current) {
        coursesRef.current.scrollLeft = 0;
      }
    }, 300);
  };

  const handleScroll = (direction) => {
    if (coursesRef.current) {
      const scrollAmount = direction === 'left' ? -270 : 270;
      coursesRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const menuItems = [
    { icon: Tv, text: "Televizyon" },
    { icon: Utensils, text: "Gastronomi" },
    { icon: Monitor, text: "Dijital" },
    { icon: PenTool, text: "Yazılım" },
  ];

  const courses = {
    Televizyon: [
      { title: "Back-end Bootcamp", date: "20 Kasım 2024", imageUrl: "https://picsum.photos/270/470?random=1" },
      { title: "Prochef Eğitimi", date: "20 Kasım 2024", imageUrl: "https://picsum.photos/270/470?random=2" },
      { title: "Chef Eğitimi", date: "20 Kasım 2024", imageUrl: "https://picsum.photos/270/470?random=3" },
      { title: "Müzik Prodüksiyonu", date: "20 Kasım 2024", imageUrl: "https://picsum.photos/270/470?random=4" },
      { title: "Front-end Bootcamp", date: "20 Kasım 2024", imageUrl: "https://picsum.photos/270/470?random=5" },
    ],
    Gastronomi: [
      { title: "Şef Yardımcılığı", date: "15 Aralık 2024", imageUrl: "https://picsum.photos/270/470?random=6" },
      { title: "Pastacılık", date: "10 Ocak 2025", imageUrl: "https://picsum.photos/270/470?random=7" },
      { title: "Dünya Mutfakları", date: "5 Şubat 2025", imageUrl: "https://picsum.photos/270/470?random=8" },
      { title: "Moleküler Gastronomi", date: "1 Mart 2025", imageUrl: "https://picsum.photos/270/470?random=9" },
    ],
    Dijital: [
      { title: "Sosyal Medya Yönetimi", date: "12 Nisan 2025", imageUrl: "https://picsum.photos/270/470?random=10" },
      { title: "SEO Uzmanlığı", date: "18 Mayıs 2025", imageUrl: "https://picsum.photos/270/470?random=11" },
      { title: "E-ticaret Stratejileri", date: "22 Haziran 2025", imageUrl: "https://picsum.photos/270/470?random=12" },
      { title: "Dijital Pazarlama", date: "30 Temmuz 2025", imageUrl: "https://picsum.photos/270/470?random=13" },
    ],
    Yazılım: [
      { title: "Full Stack Web Geliştirme", date: "5 Ağustos 2025", imageUrl: "https://picsum.photos/270/470?random=14" },
      { title: "Mobil Uygulama Geliştirme", date: "10 Eylül 2025", imageUrl: "https://picsum.photos/270/470?random=15" },
      { title: "Veri Bilimi", date: "15 Ekim 2025", imageUrl: "https://picsum.photos/270/470?random=16" },
      { title: "Siber Güvenlik", date: "20 Kasım 2025", imageUrl: "https://picsum.photos/270/470?random=17" },
    ],
  };

  const currentCourses = courses[activeCategory] || [];

  return (
    <div className="categories">
      <h1 className="categories-heading">
        EĞİTİMLER
      </h1>

      <div className="category-items">
        {menuItems.map((item) => (
          <MenuItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            isActive={activeCategory === item.text}
            onClick={() => handleCategoryChange(item.text)}
          />
        ))}
      </div>

      <div>
        <h2 className="category-title">{activeCategory} Eğitimleri</h2>
        <div className="courses-container">
          <div
            className={`courses ${fadeIn ? 'fade-in' : 'fade-out'}`}
            ref={coursesRef}
          >
            {currentCourses.map((course, index) => (
              <CourseCard key={index} {...course} />
            ))}
          </div>

          {currentCourses.length > 4 && (
            <>
              <button className="arrow-button left" onClick={() => handleScroll('left')}>
                <ChevronLeft size={24} color="white" />
              </button>
              <button className="arrow-button right" onClick={() => handleScroll('right')}>
                <ChevronRight size={24} color="white" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Categories;