import React, { useEffect, useRef } from 'react';
import './ImageSlider.css';

const LearningPlatform = () => {
  // Mevcut kod buraya gelecek (görsel URL'leri oluşturma, vb.)
  const images = [];
  for (let i = 1; i <= 20; i++) {
    images.push(`https://picsum.photos/400/600?random=${i}`);
  }

  const imagePairs = [];
  for (let i = 0; i < images.length; i += 2) {
    imagePairs.push(images.slice(i, i + 2));
  }

  const leftImageRefs = useRef([]);

  const handleImageLoad = (index) => {
    // Mevcut handleImageLoad fonksiyonu buraya gelecek
    const leftImage = leftImageRefs.current[index];
    const pair = imagePairs[index];
    if (leftImage && pair[1]) {
      const leftImageHeight = leftImage.offsetHeight;
      const rightImage = document.querySelector(`.right-image-${index}`);
      if (rightImage) {
        rightImage.style.top = `${leftImageHeight / 2}px`;
      }
    }
  };

  return (
    <div className="learning-platform">
      <div className="left-content">
        <div className="title-line"></div>
        <h2 className="title">AKADEMİ'DE HAYAT</h2>
        <p className="description">
          Acunmedya Akademi'de öğrenciler sadece öğrenmekle kalmaz, aynı
          zamanda öğrendiklerini hayata geçirirler. Sektör profesyonelleriyle
          gerçekleştirilen Masterclass'lar ve Marka Sınıfı etkinliklerinin yanı
          sıra öğrenciler burada;
        </p>
        <ul className="feature-list">
          <li>
            Tıpkı bir reklam ve pazarlama ajansında çalışır gibi "(Dream
            Team)" projesi kapsamında Akademi'nin iş süreçlerine dahil
            olurlar,
          </li>
          <li>
            Sosyal medya hesaplarının yönetiminden içerik üretimine,
            kampanya çalışmalarına, web sitesi ve tasarım projelerine kadar
            pek çok alanda aktif rol alarak gerçek iş deneyimi kazanırlar.
          </li>
        </ul>
        <p className="highlight">
          Bu sayede öğrenciler teorik bilginin ötesine geçerek işin pratiğini
          öğreniyor ve sektöre hazır hale geliyorlar.
        </p>
        <p className="conclusion">
          Acunmedya Akademi sadece eğitim alacağınız bir yer değil, gerçek
          projeler üzerinden deneyim sunarak öğrencilerin kariyerlerinde
          güçlü adımlara atmalarını sağlıyor.
        </p>
      </div>
      <div className="right-content">
        <div className="image-grid">
          {imagePairs.map((pair, index) => (
            <div key={index} className="image-pair">
              <div
                className="image-wrapper left-image"
                ref={(el) => (leftImageRefs.current[index] = el)}
              >
                <img
                  src={pair[0]}
                  alt={`Image ${index * 2 + 1}`}
                  onLoad={() => handleImageLoad(index)}
                />
              </div>
              {pair[1] && (
                <div
                  className={`image-wrapper right-image right-image-${index}`}
                  style={{ position: 'absolute', left: '50%' }}
                >
                  <img src={pair[1]} alt={`Image ${index * 2 + 2}`} />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="fade-effect"></div>
      </div>
    </div>
  );
};

export default LearningPlatform;