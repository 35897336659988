import React, { useState, useRef } from 'react';
import { Tv, Utensils, Monitor, PenTool, ChevronLeft, ChevronRight } from 'lucide-react';
import './Educators.css';

const CategoryItem = ({ icon: Icon, text, isActive, onClick }) => (
  <button
    className={`category-item ${isActive ? 'active' : ''}`}
    onClick={onClick}
  >
    <Icon size={20} color={isActive ? '#FFC107' : '#9CA3AF'} />
    <span>{text}</span>
  </button>
);

const EducatorCard = ({ name, imageUrl }) => (
  <div className="educator-card" style={{ backgroundImage: `url(${imageUrl})` }}>
    <div className="educator-card-content">
      <h3>{name}</h3>
    </div>
  </div>
);

const Educators = () => {
  const [activeCategory, setActiveCategory] = useState('Televizyon');
  const [fadeIn, setFadeIn] = useState(true);
  const educatorsRef = useRef(null);

  const handleCategoryChange = (category) => {
    setFadeIn(false);
    setTimeout(() => {
      setActiveCategory(category);
      setFadeIn(true);
      if (educatorsRef.current) {
        educatorsRef.current.scrollLeft = 0;
      }
    }, 300);
  };

  const handleScroll = (direction) => {
    if (educatorsRef.current) {
      const scrollAmount = direction === 'left' ? -270 : 270;
      educatorsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const menuItems = [
    { icon: Tv, text: "Televizyon" },
    { icon: Utensils, text: "Gastronomi" },
    { icon: Monitor, text: "Dijital" },
    { icon: PenTool, text: "Yazılım" },
  ];

  const educators = {
    Televizyon: [
      { name: "Ahmet Şirin", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/ahmet-sirin-1.png" },
      { name: "Alper Çamadan", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/Alper-Camadan-1.png" },
      { name: "Burak Kanbir", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/burak-kanbir-1.png" },
      { name: "Burcu Kurtiş", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/burcu-kurtis-1.png" },
      { name: "Doğa Özyürekli", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/doga-ozyurekli-1.png" },
      { name: "Doğa Şulen", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/doga-sulen-1.png" },
      { name: "Ghonche Ghojoghi", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/ghonche-ghojoghi.png" },
      { name: "Gözde İkinci", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/gozde-ikinci-1.png" },
      { name: "Hakan Hücum", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/hakan-hucum-2.png" },
      { name: "Hamdi Arslan", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/hamdi-arslan.png" },
      { name: "Hilal Ergenekon", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/Hilal-Ergenekon-1.png" },
      { name: "Hüseyin Çelik", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/huseyin-celik.png" },
      { name: "Kamil Özgen", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/kamil-ozgen-1.png" },
      { name: "Mirka Savic Pinhas", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/mirka-savic-pinhas-1.png" },
      { name: "Muharren Dokur", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/muharren-dokur-1.png" },
      { name: "Murat Soysal", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/murat-soysal.png" },
      { name: "Mustafa Çelik", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/mustafa-celik.png" },
      { name: "Orhan Kocak", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/orhan-kocak-1.png" },
      { name: "Osman Yelkenci", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/osman-yelkenci-1.png" },
      { name: "Özlem Tuğce Keleş", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/ozlem-tugce-keles-1.png" },
      { name: "Savaş Murat Alkım", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/savas-murat-alkim-1.png" },
      { name: "Soner Sert", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/soner-sert-1.png" },
      { name: "Süleyman Kurt", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/suleyman-kurt-2.png" },
      { name: "Umay Ayaz", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/umay-ayaz-1.png" },
      { name: "Ziyad Guliyev", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/televizyon/ziyad-guliyev.png" },
    ],
    Gastronomi: [
      { name: "Alican Gürbüz", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/alican-gurbuz-1.png" },
      { name: "Aydın Demir", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/aydin-demir-2.png" },
      { name: "Belkıs Boyacıgiller", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/belkis-boyacigiller-1.png" },
      { name: "Bigem İdrisoğlu", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/bigem-idrisoglu-1.png" },
      { name: "Cem Ekşi", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/cem-eksi-1.png" },
      { name: "Cemre Siyahi", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/cemre-siyahi-1.png" },
      { name: "Claudio Chinali", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/claudio-chinali-1.png" },
      { name: "Deniz Temel", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/deniz-temel-1.png" },
      { name: "Efe Anıl", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/efe-anil-1.png" },
      { name: "Fehmi Samancı", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/fehmi-samanci-1.png" },
      { name: "Fulya Genç", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/fulya-genc-1.png" },
      { name: "Gamze Şanlı Ak", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/Gamze-sanli-ak-1.png" },
      { name: "Gizem Gedik", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/gizem-gedik-1.png" },
      { name: "Halik Yaman", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/halik-yaman-1.png" },
      { name: "Hasan Eran Beşer", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/hasan-eran-beser-1.png" },
      { name: "Hüseyin Çiritçi", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/huseyin_ciritci-2.png" },
      { name: "Mine Ataman", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/mine-ataman-1.png" },
      { name: "Mürşit Göksüzoğlu", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/mursit-goksuzoglu-1.png" },
      { name: "Mustafa Tezel", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/mustafa-tezel-1.png" },
      { name: "Nilgün Ertuğ", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/nilgun-ertug-1.png" },
      { name: "Numan Ünsal", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/numan-unsal-1.png" },
      { name: "Ömer Tezel", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/omer-tezel-1.png" },
      { name: "Pelin Uğur Akgün", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/pelin-ugur-akgun.png" },
      { name: "Selçuk Karaçam", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/selcuk-karacam-1.png" },
      { name: "Umay Ayaz", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/umay-ayaz-3.png" },
      { name: "Uryan Doğmuş", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/uryan-dogmus-1.png" },
      { name: "Yaren Çarpar", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/yaren-carpar-1.png" },
      { name: "Zeynep Pınar Taşdemir", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/gastronomi/zeynep-pinar-tasdemir-1.png" },
    ],
    Dijital: [
      { name: "Afgan Rasulov", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/afgan-rasulov-2.png" },
      { name: "Arem Özgüç & Arman Aydın", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Arem-Özgüç-&-Arman-Aydin-1.png" },
      { name: "Aykut Başbuğ", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/aykut-basbug-1.png" },
      { name: "Barbaros Ciga", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Barbaros-Ciga-1.png" },
      { name: "Betül Uslu", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/betul-uslu-1.png" },
      { name: "Burhan Yesil", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/burhan-yesil-1.png" },
      { name: "Çağrı Demirezer", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Çağrı Demirezer.png" },
      { name: "Emre Vurer", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/emre-vurer-1.png" },
      { name: "Erdem Silay", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/erdem-silay-1.png" },
      { name: "Gökhan Gümüş", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/gokhan-gumus-2.png" },
      { name: "Levent Batırman", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Levent-Batirman-1.png" },
      { name: "Mehmet Erden", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/mehmet-erden-1.png" },
      { name: "Murat Baseren", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Murat-Baseren-1.png" },
      { name: "Onur Atlı", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Onur Atlı-1.png" },
      { name: "Orhan Ekici", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/orhan-ekici-3.png" },
      { name: "Ozan Sihay", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/ozan-sihay.png" },
      { name: "Sema Özevin", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/sema-ozevin-1.png" },
      { name: "Sercan Coşkunoğlu", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/SercanCoskunoğlu.png" },
      { name: "Serhat Bıçakçı", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/serhat-bicakci-2.png" },
      { name: "Vedat Elçigil", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/Vedat-Elcigil-1.png" },
      { name: "Veli Bahçeci", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/veli-bahceci-1.png" },
      { name: "Zafer Civelek", imageUrl: "https://nodejs-testing.acunmedyaakademi.com/photos/digital/zafer-civelek-1.png" },
    ],
    Yazılım: [
      { name: "Engin Demiroğ", imageUrl: "https://picsum.photos/271/420?random=14" },
      { name: "Murat Yücedağ", imageUrl: "https://picsum.photos/271/420?random=15" },
      { name: "Gökhan Kandemir", imageUrl: "https://picsum.photos/271/420?random=16" },
      { name: "Atıl Samancıoğlu", imageUrl: "https://picsum.photos/271/420?random=17" },
    ],
  };

  const currentEducators = educators[activeCategory] || [];

  return (
    <div className="educators">
      <h1 className="educators-heading">
        EĞİTMENLER
      </h1>

      <div className="category-items">
        {menuItems.map((item) => (
          <CategoryItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            isActive={activeCategory === item.text}
            onClick={() => handleCategoryChange(item.text)}
          />
        ))}
      </div>

      <div>
        <h2 className="category-title">{activeCategory} Eğitmenleri</h2>
        <div className="educators-container">
          <div
            className={`educators-list ${fadeIn ? 'fade-in' : 'fade-out'}`}
            ref={educatorsRef}
          >
            {currentEducators.map((educator, index) => (
              <EducatorCard key={index} {...educator} />
            ))}
          </div>

          {currentEducators.length > 4 && (
            <>
              <button className="arrow-button left" onClick={() => handleScroll('left')}>
                <ChevronLeft size={24} color="white" />
              </button>
              <button className="arrow-button right" onClick={() => handleScroll('right')}>
                <ChevronRight size={24} color="white" />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Educators;