import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form gönderildi:', email);
    };

    return (
        <div className="contact-form-container">
            <div className="contact-form">
                <h2 className="contact-form-title">İLETİŞİM FORMU</h2>
                <p className="contact-form-description">
                    Acunmedya Akademi ile gastronomi, yazılım, dijital ve televizyon alanlarında kariyerinize güçlü bir adım atın. Kariyeriniz için başvurular başladı. <em>En doğru adımları birlikte atalım.</em>
                </p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-Mail"
                        required
                    />
                    <button type="submit">ABONE OL</button>
                </form>
            </div>
        </div>
    );
};

export default ContactForm;