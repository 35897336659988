import React from 'react';
import './AcunMedyaForm.css';
import formImage from '../images/formImage.jpg'; // Görselin yolunu güncelleyin
import acunLine from '../images/acun_line.png';

const AcunMedyaForm = () => {
    return (

        <div className="acunmedya-form-container">
            <div className="form-acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div>

            <div className="form-content">

                <h1 className="form-title">HAYALİN İŞİN OLSUN!</h1>
                <p className="form-basvuru-description">
                    Acunmedya Akademi, medya, gastronomi, yazılım, dijital ve televizyon alanlarında kariyer hedefleyenler için benzersiz bir fırsat sunuyor. Güçlü networkümüz, sektörlerde lider konumda olan profesyonellerle doğrudan iletişim kurmanıza olanak sağlamaktadır. Uygulamalı eğitimlerimizle medya, gastronomi, yazılım, dijital ve televizyon alanlarında gerçek dünya senaryolarında kendinizi geliştirebilirsiniz. Ayrıca, marka sınıflarımızda sektörün dinamiklerini yerinde öğrenme fırsatını yakalayarak, kariyerinize bir adım önde başlayabilirsiniz.
                </p>
                <form className="application-form">
                    <div className="form-row">
                        <input type="text" placeholder="İsim" />
                        <input type="text" placeholder="Soyisim" />
                    </div>
                    <div className="form-row">
                        <input type="tel" placeholder="Telefon Numarası" />
                        <input type="text" placeholder="Doğum Tarihi" />
                    </div>
                    <div className="form-row">
                        <input type="email" placeholder="Email" />
                    </div>
                    <div className="form-row">
                        <select>
                            <option value="">Ülke Seçiniz</option>
                            <option value="turkey">Türkiye</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <select>
                            <option value="">Yaşadığınız Şehir</option>
                            <option value="istanbul">İstanbul</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <select>
                            <option value="">İlgilendiğiniz Bölüm</option>
                            <option value="digital-ai">Dijital - Yapay Zeka ile İçerik Üretimi</option>
                        </select>
                    </div>
                    <div className="form-checkbox">
                        <label>
                            <input type="checkbox" />
                            KVKK Aydınlatma metnini okudum kabul ediyorum.
                        </label>
                        <label>
                            <input type="checkbox" />
                            Elektronik Ticaretin Düzenlenmesi Hakkında Kanun Bilgilendirmesi'ni okudum, onaylıyorum.
                        </label>
                    </div>
                    <button type="submit" className="submit-button">HEMEN BAŞVUR</button>
                </form>
            </div>
            <div className="form-acun-line" style={{ backgroundImage: `url(${acunLine})` }}></div>

            <div className="form-image">
                <img src={formImage} alt="Acunmedya Academy" />
            </div>


        </div>
    );
};

export default AcunMedyaForm;