import React, { useState, useEffect } from 'react';
import './MasterSlider.css';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

const MasterSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      type: 'image',
      url: 'https://picsum.photos/1200/655',
      title: 'Hayalin İşin Olsun',
      description: 'Ünlü televizyoncular, usta şefler ve dijital dünyanın öncüleriyle birlikte uygulamalı eğitim ve staj fırsatlarıyla kariyerinde ilerle; hayalin gerçek olsun, hayalin işin olsun.'
    },
    {
      type: 'video',
      url: 'https://nodejs-testing.acunmedyaakademi.com/youtube.mp4',
      title: 'Eğitimlerimiz',
      description: 'Alanında uzman eğitmenlerle pratik beceriler kazanın.'
    },
    {
      type: 'image',
      url: 'https://picsum.photos/1200/655?random=1',
      title: 'Staj İmkanları',
      description: 'Sektörün önde gelen firmalarında staj yapma fırsatı.'
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <div className="master-slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`master-slider__slide ${index === currentSlide ? 'master-slider__slide--active' : ''}`}
        >
          {slide.type === 'image' ? (
            <img src={slide.url} alt={slide.title} className="master-slider__image" />
          ) : (
            <video src={slide.url} autoPlay loop muted playsInline className="master-slider__video" />
          )}
          <div className="master-slider__content">
            <h2 className="master-slider__title">{slide.title}</h2>
            <p className="master-slider__description">{slide.description}</p>
          </div>
        </div>
      ))}
      <button className="master-slider__arrow master-slider__arrow--left" onClick={prevSlide}>
        <IoIosArrowBack />
      </button>
      <button className="master-slider__arrow master-slider__arrow--right" onClick={nextSlide}>
        <IoIosArrowForward />
      </button>
      <div className="master-slider__dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`master-slider__dot ${index === currentSlide ? 'master-slider__dot--active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default MasterSlider;