import React from 'react';
import logo from './logo.svg';
import './App.css';
import Menu from './Menu';
import Slider from './components/Slider';
import WelcomeAcun from './components/WelcomeAcun';
import Educations from './components/Educations';
import WhyAcunmedya from './components/WhyAcunmedya';
import AcunMedyaForm from './components/AcunMedyaForm';
import { Helmet } from 'react-helmet';
import ImageSlider from './components/ImageSlider';
import Categories from './components/Categories';
import MemberTestimonials from './components/MemberTestimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ContactForm from './components/ContactForm';
import Educators from './components/Educators';
import MasterSlider from './components/MasterSlider';

function App() {
  return (
    <div className="App">
      <Menu />
      <MasterSlider />

      {/* <Slider height="600px" /> */}
      <WelcomeAcun />
      {/* <Educations /> */}
      <ImageSlider />
      <Categories />
      <Educators />
      <WhyAcunmedya />
      {/* <MemberTestimonials /> */}
      <AcunMedyaForm />
      <ContactForm />
      <Footer />
      <Helmet>
        <title>Acunmedya Akademi</title>
        <meta name="description" content="Acunmedya Akademi resmi web sitesi" />
        <meta name="keywords" content="Acunmedya, Akademi, Eğitim" />
      </Helmet>
    </div>
  );
}

export default App;
