import React, { useState } from 'react';
import './Menu.css';
import logo from './images/LogoIcon@3x.png';
import { FaInstagram, FaLinkedin, FaBars } from 'react-icons/fa';

const Menu = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="menu">
      <div className="menu-container">
        <div className="menu-left">
          <img src={logo} className="menu-logo" alt="Acun Medya Akademi logo" />
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          <FaBars />
        </button>
        <div className={`menu-content ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-center">
            <ul className="menu-list">
              <li className="menu-item"><a href="/egitimler">EĞİTİMLER</a></li>
              <li className="menu-item"><a href="/kariyer">KARİYER</a></li>
              <li className="menu-item"><a href="/akademide-hayat">AKADEMİDE HAYAT</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <button className="apply-button">HEMEN BAŞVUR</button>
            <div className="social-icons">
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Menu;
